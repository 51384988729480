import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DishesComponent } from './components/dishes/dishes.component';
import { CashiersComponent } from './components/cashiers/cashiers.component';
import { CreateBillComponent } from './components/create-bill/create-bill.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ViewBillComponent } from './components/view-bill/view-bill.component';
import { BranchesComponent } from './components/branches/branches.component';
import { HallsComponent } from './components/halls/halls.component';
import { ExpenseComponent } from './components/expense/expense.component';
import { UsersComponent } from './users/users.component';
import { AuthGuard } from './guards/auth.guard';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SettingsComponent } from './components/settings/settings.component';
import { OrdersDisplayComponent } from './orders-display/orders-display.component';
import { ViewOrdersComponent } from './view-orders/view-orders.component';


const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'orderList', component: OrdersDisplayComponent},
    {path: 'createbill', component: CreateBillComponent, data: {role: 'cashier'}, canActivate: [AuthGuard]},
    {path: 'orders', component: OrdersComponent},
    {path: 'viewBill/:type/:billId', component: ViewBillComponent},
    {path: 'viewOrder/:dispatchOrderId', component: ViewOrdersComponent},
    {path: 'branches', component: BranchesComponent, data: {role: 'user'}, canActivate: [AuthGuard]},
    {path: 'halls', component: HallsComponent, data: {role: 'user'}, canActivate: [AuthGuard]},
    {path: 'admins', component: UsersComponent, data: {role: 'user'}, canActivate: [AuthGuard]},
    {path: 'cashiers', component: CashiersComponent, data: {role: 'user'}, canActivate: [AuthGuard]},
    {path: 'dishes', component: DishesComponent, data: {role: 'user cashier'}, canActivate: [AuthGuard]},
    {path: 'expense', component: ExpenseComponent, data: {role: 'user cashier'}, canActivate: [AuthGuard]},

    {path: 'reports', component: ReportsComponent, data: {role: 'user cashier'}, canActivate: [AuthGuard]},
    {path: 'settings', component: SettingsComponent, data: {role: 'user'}, canActivate: [AuthGuard]},
    {path: 'unauth', component: UnauthorizedComponent},

    {path: '**', component: PageNotFoundComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
