import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
    roleId: any = `${localStorage.getItem('role_id')}`;
    userId: any = `${localStorage.getItem('id')}`;
    roleName: any = `${localStorage.getItem('role')}`;
    dashboardDetails: any = [];
    branchId: any = `${localStorage.getItem('branchId')}`;
    branchClass = -1;
    activeBranchList: any = [];
    allBranch;
    chooseBranch;
    employee = true;
    branchName: any = `${localStorage.getItem('branchName')}`;
    loading = false;
    selectBranchId: any = [];
    selectBranchName: any = [];
    todayBills: any = [];
    viewBill: any = [];
    billDishes: any = '';
    hallName: any = '';
    cashierName: any = '';
    serverName: any = '';
    currentBillArray: any = [];
    operators: number = 0;
    num: number = 1;
    constructor(private apiService: ApiService, private toastr: ToastrService, private router: Router) { }

    ngOnInit() {
        if (this.roleId === '1' || this.roleId === '2') {
            this.branchId = 0;
        }
        this.listActiveBranches();
        this.dashboard();

        // if (this.roleId === '3') {
        //     this.listTodayBills();
        // }
    }

    ngAfterViewInit(): void {
        // if (this.roleId === '3') {
        //     setTimeout(() => {
        //             const alert: HTMLElement = document.querySelector('#notification');
        //             alert.classList.add('alert-print');
        //         }, 2000);
        // }
    }

    dashboard() {
        this.loading = true;
        this.allBranch = true;
        this.branchClass = -1;
        this.apiService.getData(`dashboard/${this.branchId}`).subscribe((data) => {
            this.dashboardDetails = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    branchDashboard(id, index=0) {
        this.loading = true;
        this.allBranch = false;
        this.branchClass = index;
        this.apiService.getData('dashboard', 1).subscribe((data) => {
            this.dashboardDetails = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    billDown() {
        this.viewBill = [];
        this.operators++;
        if (this.operators >= this.todayBills.length) {
            alert('bill end reached');
            this.operators = this.todayBills.length - 1;
            this.viewBill = this.todayBills[this.operators];
            return false;
        }
        this.viewBill = this.todayBills[this.operators];
    }

    billUp() {
        this.viewBill = [];
        this.operators--;
        if (this.operators <= -1) {
            alert('bill end reached');
            this.operators = 0;
            this.viewBill = this.todayBills[this.operators];
            return false;
        }
        this.viewBill = this.todayBills[this.operators];
    }

    async listActiveBranches() {
        await this.apiService.getData('listActiveBranches').subscribe((data) => {
            const list = data.data;
            if (this.roleName === 'user') {
                this.activeBranchList = list;
            } else {
                this.selectBranchId = `${localStorage.getItem('branchId')}`;
                this.selectBranchName = `${localStorage.getItem('branchName')}`;
                this.setBranchName();
            }
        });
    }

    setBranchName() {
        this.activeBranchList = [{
            branch_id: this.selectBranchId, branch_name: this.selectBranchName
        }];
        setTimeout(() => {
            const branchClick = document.querySelector('#branchname') as HTMLElement;
            branchClick.click();
        });
    }

    listTodayBills() {
        this.viewBill = [];
        this.apiService.getData(`listTodayBills/${this.branchId}`).subscribe((data) => {
            this.todayBills = data.data;
            if (this.todayBills.length !== 0) {
                this.viewBill = this.todayBills[0];
                this.billDishes = this.viewBill.bill_dish;
                this.hallName = this.viewBill.hall[0].hall_name;
                this.cashierName = this.viewBill.cashier.length !== 0 ? this.viewBill.cashier[0].cashier_name : 'No Cashier';
                this.serverName = this.viewBill.server.length !== 0 ? this.viewBill.server[0].server_name : 'No Server';
            }
        }, error => {
            console.log(error);
        });
    }

    printBill() {
        const printContent = document.getElementById('bill_container');
        const WindowPrt = window.open('', '', 'left=0,top=0,width=700,height=700,toolbar=0,scrollbars=0,status=0');
        // tslint:disable-next-line: max-line-length
        WindowPrt.document.write('<style type="text/css">.invoice_container{color:#100a6c;position:relative;padding:20px 0;background-color:#fff}.invoice_container::before{position:absolute;top:30%;left:50%;transform:translate(-50%,50%) rotate(-35deg);font-size:50px;font-weight:700;color:#ccc;z-index:-1;opacity:.6}.invoice_container header{text-align:center;border-bottom:5px solid #100a6c}.invoice_container header p{margin:0 auto;font:18px/24px sans-serif}.invoice_container header strong{display:block}.invoice_wrapper{border:1px solid #100a6c;margin:40px 30px}.invoice_wrapper div{padding:5px 20px;border-bottom:1px solid #100a6c}table{width:100%;border-collapse:collapse}.tax_invoice table td{width:300px;color:#100a6c;font:16px/20px sans-serif}.tax_invoice h2{font:18px/24px sans-serif;padding-left:60px;margin-bottom:10px}.Receipent p{position:relative;height:100px}.bill-details{padding:0!important}.bill-details table td,.bill-details table th{border-left:1px solid #100a6c;color:#100a6c;text-align:center}.bill-details table tbody tr{height:30px}.bill-details table tr td:nth-child(1),.bill-details table tr th:nth-child(1){border-left:0}.bill-details table thead th{padding:15px 0}.bill-details table tfoot td{border-top:1px solid #100a6c;border-left:0;padding:15px 0;font-weight:700}.invoice_wrapper footer{padding:50px 50px 5px}.invoice_wrapper footer span:last-child{float:right}.termsCondition{font:500 14px/22px monospace}#bill_details1{border-bottom:none;text-align:left;width:50%;float:left}#bill_details2{border-bottom:none;text-align:left;width:30%;margin-left:5%;float:left}.billing_details span{width:90px;display:inline-block}#bill_details2 span{width:75px}.bill_address{border-bottom:2px dashed}</style>');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.print();
        WindowPrt.document.close();
    }

    notifyClose() {
        const alert: HTMLElement = document.querySelector('#notification');
        alert.classList.remove('alert-print');
    }



}
