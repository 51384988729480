import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import Pusher from 'pusher-js';
import {Howl, Howler} from 'howler';
import { Router } from '@angular/router';

@Component({
  selector: 'app-orders-display',
  templateUrl: './orders-display.component.html',
  styleUrls: ['./orders-display.component.css']
})
export class OrdersDisplayComponent implements OnInit {

    locationDetailsId: any = `${localStorage.getItem('location_details_id')}`;
    pusher = new Pusher('8d0ad959b5759a0aa3ca', {
      cluster: 'ap2'
    });
    OrderList = [];
    loading = false;
  singleImage: any;
  dispatchOrderId;
  orderId;
  dispatchOrders:any=[];
  mobile:any
  portrait: boolean = false;
  landscape: boolean = false;

  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    var channel = this.pusher.subscribe('blaack-forest');
        channel.bind(`dispatch-${this.locationDetailsId}`, (data) => {
           if(this.router.url == '/orderList'){
            this.listAllDispatchOrders();
          }
      });
    this.listAllDispatchOrders();
    // if(this.mobile){
    //   if (window.matchMedia("(orientation: portrait)").matches) {
    //     this.portrait = true
    //  } else if(window.matchMedia("(orientation: landscape)").matches){
    //               this.portrait = false
    //               this.landscape = true
    //   alert("you are in landscape mode")  
    //  }
    // }
 
  }

  listAllDispatchOrders(){
    this.loading = true;
    this.apiService.getData(`getDispatchOrderById/${this.locationDetailsId}`).subscribe((data) => {
      this.dispatchOrders = data.data;
       this.OrderList = data.data[0].order.ordered_products;
       this.dispatchOrderId = data.data[0].dispatch_order_id;
       this.orderId = data.data[0].order_id;

        this.loading = false;
    }, error => {
        this.loading = false;
    });
  }

}
