import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Pusher from 'pusher-js';

@Component({
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: ['./view-orders.component.css']
})
export class ViewOrdersComponent implements OnInit {
  preparedImg:any
  pusher = new Pusher('8d0ad959b5759a0aa3ca', {
    cluster: 'ap2'
  });
p = 1;
  OrderList:any = [];
  loading: boolean;
  dispatchOrderId = this.route.snapshot.paramMap.get('dispatchOrderId');
  productList:any= [];
  dispatchStatus;
  dispatchOrder: any = [];
  viewImage = false;
  viewImage2 = false;
  errorMsg: any = [];
  productId;
  selectedImage:any = '';
  selectedName;
  dispatchPrepareImageId:any = 0;
  choosed:any = false;
  imageView:any = '';
  dispatchPrepareImages:any = [];
  orderId;
  cakeDoneImage: any;
  viewComment: boolean;
  comment: string;
  addonList: any;
  imageUrl: string;
  locationDetailsId: any = `${localStorage.getItem('location_details_id')}`;
  dispatchApprovalProdName: any;



  constructor(private apiService: ApiService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router) { }
  @ViewChild('dishImage', { static: false }) dishImage: ElementRef;
  ngOnInit() {
    var channel = this.pusher.subscribe('blaack-forest');
    channel.bind(`dispatch-${this.locationDetailsId}`, (data) => {
      // console.log(this.router.url);
      // console.log('/viewOrder/'+this.dispatchOrderId);
       if(this.router.url == '/viewOrder/'+this.dispatchOrderId){
        this.listDispatchOrder();
        this.getDispatchImages();
      }
  });
  this.listDispatchOrder();
  this.getDispatchImages();
  }

    listDispatchOrder() {

      this.apiService.getData(`getDispatchOrderByDispatchId/${this.dispatchOrderId}`).subscribe((data) => {

        this.dispatchOrder = data.data;
        this.addonList =data.data[0].order.ordered_addons;
        this.OrderList = data.data[0].order.ordered_products;
        this.dispatchStatus= data.data[0].dispatch_order_status;

        this.orderId = data.data[0].order_id;
        this.loading = false;
    }, error => {
        this.loading = false;
    });
  }
  onlyStartTime(slot){
    let splitArray = slot.split("to");
    return splitArray[0];
  }
  getDispatchImages(){
    this.apiService.getData(`getDispatchImagesById/${this.dispatchOrderId}`).subscribe((data) => {

      this.dispatchPrepareImages= data.data;


      this.loading = false;
  }, error => {
      this.loading = false;
  });

  }

  acceptOrders(){

    const value={
      dispatch_order_id: this.dispatchOrderId
    }

    this.apiService.postData(value, 'acceptDispatchOrders').subscribe((data) => {
      if (data.error === false) {
        this.toastr.success(data.message);
        this.listDispatchOrder();

        this.loading = false;
    } else {
      this.errorMsg = data.data;
        this.toastr.error(data.message);
        this.loading = false;
        setTimeout(() => {
            this.errorMsg = [];
        }, 3000);
    }
      this.loading = false;
  }, error => {
      this.loading = false;
  });
  }


  openImagePopUp(product_id="", image_name="") {

      this.productId = product_id;
      this.selectedName = image_name;
      this.viewImage = true;
  }
  openCommentPopUp(comments="") {
    this.viewComment = true;
    this.comment = comments;
}

  openImagePopUp1(id="", product_id="", image_name="") {
    console.log(id);
    if(id){
      this.dispatchPrepareImageId = id;
      this.productId = product_id;
      this.selectedName = image_name;
      this.viewImage = true;
    }


  }
  closeCommentPopUp() {
    this.viewComment = false;
}

closeImagePopUp() {
    this.viewImage = false;
}
closeImagePopUp2() {
  this.viewImage2 = false;
}

updateDishImage() {
  this.loading = true;
    const formData = new FormData();
    console.log(this.selectedImage);
    formData.append('location_details_id', this.locationDetailsId);
    if(this.dispatchPrepareImageId == 0){
      formData.append('dish_image', this.selectedImage);
      formData.append('product_id', this.productId);
      formData.append('dispatch_order_id', this.dispatchOrderId);
      formData.append('image_name', this.selectedName);
    }else{
      formData.append('dish_image', this.selectedImage);
      formData.append('product_id', this.productId);
      formData.append('dispatch_order_id', this.dispatchOrderId);
      formData.append('image_name', this.selectedName);
      formData.append('dispatch_prepare_image_id', this.dispatchPrepareImageId);
    }


    this.apiService.postData(formData, 'dispatchApproveImage').subscribe((data) => {
      this.loading = false;
        if(!data.error) {
            this.toastr.success(data.message);
            this.closeImagePopUp();
            this.listDispatchOrder();
            this.getDispatchImages();
            this.choosed = false;
            this.selectedImage = "";
            this.dishImage.nativeElement.value = "";
        } else {
            this.toastr.warning(data.message);
        }
    })
}



onSelectFile(event) {

  console.log(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
        this.choosed = true;
        this.selectedImage = event.target.files[0];
        this.selectedName = event.target.files[0].name;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.imageView = reader.result;
        }
    }
}
playAudio(){
  let audio = new Audio();
  audio.src = "../../../assets/audio/alarm.wav";
  audio.load();
  audio.play();
}

openDispatchUploadImg(image,i,productName){
  this.preparedImg = image
  this.dispatchApprovalProdName = productName
  this.viewImage2 = true;
}

}
