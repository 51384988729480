import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-view-bill',
    templateUrl: './view-bill.component.html',
    styleUrls: ['./view-bill.component.css']
})
export class ViewBillComponent implements OnInit {

    num: number = 1;
    viewBill: any = '';
    billDishes: any = 0;
    hallName: any = '';
    cashierName: any = '';
    serverName: any = '';
    tableName: any = '';
    searchField: FormControl = new FormControl();
    role = `${localStorage.getItem('role')}`;
    billType = '';
    settings:any = '';

    constructor(private apiService: ApiService, private toastr: ToastrService, private route: ActivatedRoute) { }

    ngOnInit() {
        this.listBillDetails();
        this.billType = this.getRouterValue('type');
        this.getSettings();
    }

    getRouterValue(value) {
        return this.route.snapshot.paramMap.get(value);
    }

    listBillDetails() {
        const params = `${this.getRouterValue('type')}/${this.getRouterValue('billId')}`;
        this.apiService.getData('listBillDetails', params).subscribe((data) => {
            if(this.billType === 'order') {
                this.viewBill = data.data;
                this.billDishes = data.data.bill_dish;
                this.hallName = data.data.hall[0].hall_name;
                this.cashierName = data.data.cashier.length !== 0 ? data.data.cashier[0].cashier_name : 'No Cashier';
            }
        });
    }

    printBill() {
        const printContent = document.getElementById('bill_container');
        const WindowPrt = window.open('', '', 'left=0,top=0,width=700,height=700,toolbar=0,scrollbars=0,status=0');
        // tslint:disable-next-line: max-line-length
        WindowPrt.document.write('<style type="text/css"></style>');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.print();
        WindowPrt.document.close();
    }

    getSettings() {
        this.apiService.getData('getSettings').subscribe((data) => {
            this.settings = data.data;
        });
    }

}
