import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';


@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

    todayBills: any = [];
    p: number = 1;
    branchId: any = `${localStorage.getItem('branchId')}`;
    role = `${localStorage.getItem('role')}`;
    roleId: any = `${localStorage.getItem('role_id')}`;
    activeBranchList = [];
    branchClass = -1;
    allBranch;
    loading = false;
    constructor(private apiService: ApiService) { }

    ngOnInit() {
        if (this.role === 'user' ) {
            this.branchId = '0';
            this.listTodayBills();
        } else {
            this.listTodayBills();
        }
        this.listActiveBranches();
    }

    listTodayBills() {
        this.loading = true;
        this.allBranch = true;
        this.branchClass = -1;
        this.apiService.getData(`listTodayBills/${this.branchId}`).subscribe((data) => {
            this.todayBills = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    branchTodayBills(id, index) {
        this.loading = true;
        this.allBranch = false;
        this.branchClass = index ;
        this.apiService.getData('listTodayBills', id).subscribe((data) => {
            this.todayBills = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    listActiveBranches() {
        this.loading = true;
        this.apiService.getData('listActiveBranches').subscribe((data) => {
            this.activeBranchList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

}
