import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
    user = `${localStorage.getItem('dispatch_name')}`;
    branchName: string;
    // role: any = `${localStorage.getItem('role')}`;
    // roleName: any = `${localStorage.getItem('role_name')}`;
    constructor() { }

    ngOnInit() {

        setTimeout(() => {
            this.branchName = `${localStorage.getItem('location_name')}`;
        }, 2000);
    }

}
