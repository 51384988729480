import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
    providers: [DatePipe]
})
export class ReportsComponent implements OnInit {
    today = new Date();
    todayStockReport = [];
    stockDate = [];
    todayExpenseReport = [];
    todayExpenseTotal = [];
    expenseDate = [];
    todayBillReport = [];
    todayBillTotal: any = 0;
    billDate = [];
    todayIngredientReport = [];
    IngredientDate = [];
    todayProductReport = [];
    productDate = [];
    todayCashierReport = [];
    todayCashierRevenue = [];
    cashierDate = [];
    todayServerReport = [];
    todayServerRevenue = [];
    serverDate = [];
    todayHallReport = [];
    todayHallRevenue = [];
    hallDate = [];
    activeHallList = [];
    hallId = '';
    p = 1;
    errorMsg = false;
    loading = false;
    activeBranchList = [];
    selectBranchId: any = '';
    selectBranchName: any = '';
    branchClass = -1;
    roleId: any = `${localStorage.getItem('role_id')}`;
    roleName: any = `${localStorage.getItem('role')}`;
    dishReportTotal = 0;
    showBill = false;
    showExpense = false;
    showDish = false;
    showHalls = false;
    date:any = '';
    time = '';
    constructor(private apiService: ApiService, private datePipe: DatePipe, private toastr: ToastrService) { }

    ngOnInit() {
        this.listActiveBranches();
    }

    listAllReports() {
        this.getExpenseReports('today');
        this.getBillReports('today');
        this.getProductReport('today');
        // this.getCashierReport('today');
        this.getHallDetails();
    }

    listActiveBranches() {
        this.loading = true;
        this.apiService.getData('listActiveBranches').subscribe((data) => {
            this.loading = false;
            if (this.roleName === 'user') {
                this.activeBranchList = data.data;
            } else {
                this.selectBranchId = `${localStorage.getItem('branchId')}`;
                this.selectBranchName = `${localStorage.getItem('branchName')}`;
                this.activeBranchList = [{
                    branch_id: this.selectBranchId, branch_name: this.selectBranchName
                }];
            }
            if (this.activeBranchList.length !== 0) {
                setTimeout(() => {
                    const branchClick = document.querySelector('#branchname') as HTMLElement;
                    branchClick.click();
                });
            }
        }, error => {
            this.loading = false;
        });
    }

    branchNameSelect(id, name, index) {
        this.hallId = '';
        this.todayHallReport = [];
        this.todayHallRevenue = [];
        this.p = 1;
        this.selectBranchId = id;
        this.selectBranchName = name;
        this.branchClass = index;
        this.listAllReports();
    }

    // ExpensesReport

    formatCurrentDate() {
        return {
            currentDate: formatDate(new Date(this.today), 'yyyy-MM-dd', 'en'), branch_id: this.selectBranchId
        };
    }

    formatFromToDate(from, to) {
        return {
            fromDate: formatDate(new Date(from), 'yyyy-MM-dd', 'en'),
            toDate: formatDate(new Date(to), 'yyyy-MM-dd', 'en'),
            branch_id: this.selectBranchId
        };
    }

    getExpenseReports(tags) {
        this.loading = true;
        let params: any = '';
        if (tags === 'today') {
            params = this.formatCurrentDate();
            this.expenseDate = [];
        } else if (tags === 'multidate') {
            if (this.expenseDate === null || this.expenseDate.length === 0) {
                this.toastr.error('Please Choose a Date');
                this.loading = false;
                return false;
            } else {
                params = this.formatFromToDate(this.expenseDate[0], this.expenseDate[1]);
            }
        }
        this.apiService.postData(params, 'getExpenseReports').subscribe((data) => {
            this.todayExpenseReport = data.data.expenseReports;
            this.todayExpenseTotal = data.data.expenseTotal.expenseAmount;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getStockReports(tags) {
        this.loading = true;
        let params: any = '';
        if (tags === 'today') {
            this.loading = true;
            params = this.formatCurrentDate();
            this.stockDate = [];
        } else if (tags === 'multidate') {
            if (this.stockDate === null || this.stockDate.length === 0) {
                this.toastr.error('Please Choose a Date');
                this.loading = false;
                return false;
            } else {
                params = this.formatFromToDate(this.stockDate[0], this.stockDate[1]);
            }
        }
        this.apiService.postData(params, 'getStockReports').subscribe((data) => {
            this.todayStockReport = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getBillReports(tags) {
        this.todayBillTotal = 0;
        this.loading = true;
        let params: any = '';
        if (tags === 'today') {
            params = this.formatCurrentDate();
            this.billDate = [];
        } else if (tags === 'multidate') {
            if (this.billDate === null || this.billDate.length === 0) {
                this.toastr.error('Please Choose a Date');
                this.loading = false;
                return false;
            } else {
                params = this.formatFromToDate(this.billDate[0], this.billDate[1]);
            }
        }
        this.apiService.postData(params, 'getBillReports').subscribe((data) => {
            this.todayBillReport = data.data;
            this.loading = false;
            this.todayBillReport.forEach(element => {
                this.todayBillTotal += +element.dish_overall_total;
            });
        }, error => {
            this.loading = false;
        });
    }

    getIngredientReports(tags) {
        this.loading = true;
        let params: any = '';
        if (tags === 'today') {
            params = this.formatCurrentDate();
            this.IngredientDate = [];
        } else if (tags === 'multidate') {
            if (this.IngredientDate === null || this.IngredientDate.length === 0) {
                this.toastr.error('Please Choose a Date');
                this.loading = false;
                return false;
            } else {
                params = this.formatFromToDate(this.IngredientDate[0], this.IngredientDate[1]);
            }
        }
        this.apiService.postData(params, 'getIngredientReports').subscribe((data) => {
            this.todayIngredientReport = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getProductReport(tags) {
        this.loading = true;
        let params: any = '';
        if (tags === 'today') {
            params = this.formatCurrentDate();
            this.productDate = [];
        } else if (tags === 'multidate') {
            if (this.productDate === null || this.productDate.length === 0) {
                this.toastr.error('Please Choose a Date');
                this.loading = false;
                return false;
            } else {
                params = this.formatFromToDate(this.productDate[0], this.productDate[1]);
            }
        }
        this.apiService.postData(params, 'getItemReports').subscribe((data) => {
            this.todayProductReport = data.data;
            this.loading = false;
            this.todayProductReport.forEach(element => {
                this.dishReportTotal += +element.dish_overall_total;
            });
        }, error => {
            this.loading = false;
        });

    }

    // CashierReport

    getCashierReport(tags) {
        this.loading = true;
        let params: any = '';
        if (tags === 'today') {
            params = this.formatCurrentDate();
            this.cashierDate = [];
        } else if (tags === 'multidate') {
            if (this.cashierDate === null || this.cashierDate.length === 0) {
                this.toastr.error('Please Choose a Date');
                this.loading = false;
                return false;
            } else {
                params = this.formatFromToDate(this.cashierDate[0], this.cashierDate[1]);
            }
        }
        this.apiService.postData(params, 'getCashierReports').subscribe((data) => {
            this.todayCashierReport = data.data.data;
            this.todayCashierRevenue = data.data.cashier_revenue;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getServerReport(tags) {
        this.loading = true;
        let params: any = '';
        if (tags === 'today') {
            params = this.formatCurrentDate();
            this.serverDate = [];
        } else if (tags === 'multidate') {
            if (this.serverDate === null || this.serverDate.length === 0) {
                this.toastr.error('Please Choose a Date');
                this.loading = false;
                return false;
            } else {
                params = this.formatFromToDate(this.serverDate[0], this.serverDate[1]);
            }
        }
        this.apiService.postData(params, 'getServerReports').subscribe((data) => {
            this.todayServerReport = data.data.data;
            this.todayServerRevenue = data.data.server_revenue;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    // HallReport

    getHallDetails() {
        this.apiService.getData('listAllHalls', this.selectBranchId).subscribe(data => {
            this.activeHallList = data.data;
        });
    }

    getHallReport(tags) {
        this.loading = true;
        if (this.hallId === '') {
            this.errorMsg = false;
            this.toastr.error('Please choose a Hall First');
            this.loading = false;
            return false;
        }
        this.errorMsg = true;
        let params: any = '';
        if (tags === 'today') {
            params = this.formatCurrentDate();
            this.hallDate = [];
            this.loading = true;
        } else if (tags === 'multidate') {
            if (this.hallDate === null || this.hallDate.length === 0) {
                this.toastr.error('Please Choose a Date');
                this.loading = false;
                return false;
            } else {
                params = this.formatFromToDate(this.hallDate[0], this.hallDate[1]);
            }
        }
        this.apiService.postData(params, `getHallReports/${this.hallId}`).subscribe((data) => {
            this.todayHallReport = data.data.data;
            this.todayHallRevenue = data.data.hall_bills_total;
            // this.todayHallReport.push({Total_Amount: this.todayHallRevenue});
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    exportXl(list, name) {
        const workBook = XLSX.utils.book_new(); // create a new blank book
        const workSheet = XLSX.utils.json_to_sheet(list);

        XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
        XLSX.writeFile(workBook, `${name}.xlsx`); //
    }

    printBill(type) {
        this.date = formatDate(new Date(this.today), 'yyyy-MM-dd', 'en');
        this.time = formatDate(new Date(new Date), 'hh:mm a', 'en');
        if(type === 'bill') {
            this.showBill =true;
            setTimeout(() => {
                this.printBillReports('bill_reports_container');
            }, 500);
        } else if(type === 'expense') {
            this.showExpense =true;
            setTimeout(() => {
                this.printBillReports('expense_reports_container');
            }, 500);
        } else if(type === 'dish') {
            this.showDish =true;
            setTimeout(() => {
                this.printBillReports('dish_reports_container');
            }, 500);
        } else if(type === 'hall') {
            this.showHalls =true;
            setTimeout(() => {
                this.printBillReports('hall_reports_container');
            }, 500);
        }
    
    }

    printBillReports(id) {
        const printContent = document.getElementById(id);
        const WindowPrt = window.open('', '', 'left=0,top=0,width=700,height=700,toolbar=0,scrollbars=0,status=0');
        // tslint:disable-next-line: max-line-length
        WindowPrt.document.write('<style type="text/css">.invoice_container{color:#100a6c;position:relative;padding:20px 0;background-color:#fff}.invoice_container::before{position:absolute;top:30%;left:50%;transform:translate(-50%,50%) rotate(-35deg);font-size:50px;font-weight:700;color:#ccc;z-index:-1;opacity:.6}.invoice_container header{text-align:center;border-bottom:5px solid #100a6c}.invoice_container header p{margin:0 auto;font:18px/24px sans-serif}.invoice_container header strong{display:block}.invoice_wrapper{border:1px solid #100a6c;margin:40px 30px}.invoice_wrapper div{padding:5px 20px;border-bottom:1px solid #100a6c}table{width:100%;border-collapse:collapse}.tax_invoice table td{width:300px;color:#100a6c;font:16px/20px sans-serif}.tax_invoice h2{font:18px/24px sans-serif;padding-left:60px;margin-bottom:10px}.Receipent p{position:relative;height:100px}.bill-details{padding:0!important}.bill-details table td,.bill-details table th{border-left:1px solid #100a6c;color:#100a6c;text-align:center}.bill-details table tbody tr{height:30px}.bill-details table tr td:nth-child(1),.bill-details table tr th:nth-child(1){border-left:0}.bill-details table thead th{padding:15px 0}.bill-details table tfoot td{border-top:1px solid #100a6c;border-left:0;padding:15px 0;font-weight:700}.invoice_wrapper footer{padding:50px 50px 5px}.invoice_wrapper footer span:last-child{float:right}.termsCondition{font:500 14px/22px monospace}#bill_details1{border-bottom:none;text-align:left;width:50%;float:left}#bill_details2{border-bottom:none;text-align:left;width:30%;margin-left:5%;float:left}.billing_details span{width:90px;display:inline-block}#bill_details2 span{width:75px}.bill_address{border-bottom:2px dashed}</style>');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.print();
        WindowPrt.document.close();
        this.showBill = this.showExpense = this.showDish = this.showHalls = false;
    }

}
