import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

    settingList: any = '';
    cgst:any = '';
    sgst:any = '';
    parcel:any = '';
    constructor(private apiService: ApiService, private toastr: ToastrService) { }

    ngOnInit() {
        this.getSettings();
    }

    getSettings() {
        this.apiService.getData('getSettings').subscribe((data) => {
            this.settingList = data.data;
            this.cgst = this.settingList.cgst_percentage;
            this.sgst = this.settingList.sgst_percentage;
            this.parcel = this.settingList.parcel_percentage;
        });
    }

    setSettings() {
        const value = {
            cgst_percentage : this.cgst,
            sgst_percentage : this.sgst,
            parcel_percentage : this.parcel
        };
        this.apiService.postData(value, 'setSettings').subscribe((data) => {
            if(!data.error) {
                this.toastr.success(data.message);
                this.getSettings();
            }
        });
    }

}
